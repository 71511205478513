import {Component, Input} from '@angular/core';
import {TABLE_ICONS_MAP, TableIconEnum, parseString} from '@shared/components/table';
import {NbIconModule} from '@nebular/theme';

@Component({
  selector: 'mkad-table-icon',
  standalone: true,
  imports: [NbIconModule],
  template: `
    @if (icon) {
      <nb-icon [pack]="parseString(iconsMap[icon].pack)" [icon]="parseString(iconsMap[icon].icon)"/>
    }
  `,
})
export class TableIconComponent {
  @Input({transform: (value: TableIconEnum | null): TableIconEnum | null => value, required: true}) icon?: TableIconEnum = TableIconEnum.pen;
  protected readonly iconsMap = TABLE_ICONS_MAP;
  protected readonly parseString = parseString;
}
