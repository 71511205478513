import {Component, Input} from '@angular/core';
import {NbComponentOrCustomStatus} from '@nebular/theme';

export const ICONS_STATUS: Record<string, string> = {
  'success': 'circle-check',
  'warning': 'circle-exclamation',
  'danger': 'circle-xmark',
  'info': 'circle-info',
  'primary': 'earth-americas',
};

@Component({
  selector: 'mkad-alert',
  template: `
    @if (message) {
      <section [class]="'mkad__alert gap-2 relative w-full flex align-items-start border-round-md py-3 px-3 status_' + status">
        <i [class]="'text-lg fa-regular fa-' + iconsStatus[status]"></i>
        {{ message }}
      </section>
    }
  `,
  standalone: true,
  imports: [],
})
export class MkadAlertComponent {
  @Input() message: string = '';
  @Input() status: NbComponentOrCustomStatus = 'info';
  iconsStatus = ICONS_STATUS;
}
